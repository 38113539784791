import React from "react";
import Layout from "../components/layout";
import Hero from "../components/hero/hero";
import Row from "../components/row/row";
import { StaticImage } from "gatsby-plugin-image";
import * as AboutStyle from './about.module.css'
import MandalaImg from '../images/mandala3.png'

export default function About() {
    return (
        <Layout pageTitle={'Home'}>
            <Hero titleText={``} subText={``} hasButton={false}>
                <StaticImage
                    src={`../images/contact.png`}
                    alt="A picture of Heidi"
                    placeholder="blurred"
                    layout="fullWidth"
                    objectFit="cover"
                    quality={100}
                    className={AboutStyle.image}
                />
                <div className="darken-image"></div>
                {/* <StaticImage
                    src={`../images/mandala4.png`}
                    layout="fixed"
                    width={100}
                    className={`${AboutStyle.image2}`}
                /> */}
            </Hero>
            <div className="block-3x"></div>
            <Row >
                <div className="column is-1"></div>
                <div className="column is-4 is-family-fancy title is-size-2">
                    <p className="has-text-right-desktop  has-text-centered-mobile">Close your eyes.</p>
                    <div className="is-clearfix"></div>
                    <p className="has-text-right-desktop  has-text-centered-mobile">Fall in love.</p>
                    <div className="is-clearfix"></div>
                    <p className="has-text-right-desktop  has-text-centered-mobile">Stay there.</p>
                    <div className="is-clearfix"></div>
                    <p className="has-text-right-desktop  has-text-centered-mobile is-size-4">- Rumi</p>
                </div>
                <div className="column is-5 pl-6 pr-6">
                    <p className="has-text-justified">
                        Heidi discovered her love for Yoga at the age of 5. Similar to the words of Rumi, Heidi instantly felt a love and inner connection that is still alive today. Heidi intuitively knew she would pursue her interest in Yoga and, by the age of 8, she knew that she would visit India to study Yoga and ride elephants. Twelve years later, Heidi left Canada to travel the world and fulfill her dreams to study Yoga around the globe. Heidi continues to study and teach Yoga locally and internationally with enthusiasm, innovation and passion.
                    </p>
                </div>
                <div className="column is-2"></div>
            </Row>
            <Row styles={`mt-6 mb-2 pt-6 pb-6 has-background-primary has-text-white is-family-secondary`}>
                <div className="column is-3"></div>
                <div className="column is-6">
                    <p className="title is-size-2  has-text-white  has-text-centered has-text-weight-semibold">Roots</p>
                    <p className="is-family-primary ml-3">Heidi’s Yoga roots include studies in Classical Hatha Yoga with emphasis on Iyengar, Ashtanga and Sivananda styles. She has studied with many teachers including Hart Lazer, Ramanand Patel, Father Joe Pereira, Ana Forest, Alan Finger and has enjoyed time spent at the Kripalu Centre as well as Sivananda Ashrams around the world. It is out of deep respect for the philosophy of Yoga and the teachers she has been fortunate to meet on her path that she continues to uphold the more traditional practices of Yoga.</p>
                    <p className="is-family-primary mt-5 ml-3">Heidi has studied in Britain, India, Nepal, Thailand, Canada and the United States. Her mandate is to help individuals deepen their body/mind/spirit connection both on and off the mat. Her unique ability to teach with knowledge, compassion and humour has helped thousands of people connect more deeply within themselves.</p>
                </div>
                <div className={`column is-3 is-hidden-mobile-only ${AboutStyle.mandalaDiv}`}>
                    <img src={MandalaImg} className={`${AboutStyle.mandala}`} />
                </div>
            </Row>
            <Row>
                <div className="column is-12">
                    <StaticImage
                        src={`../images/about.jpg`}
                        alt="A picture of Heidi"
                        placeholder="blurred"
                        layout="fullWidth"
                        objectFit="cover"
                        quality={100}
                    />
                </div>
            </Row>
        </Layout>
    )
}